import dynamic from 'next/dynamic';

import { NotionProps } from 'notion/types';

const createComponents = (content: any[]) => {
  return content
    .filter((block) => blockTypes[block.type])
    .map((block) => {
      const Component = blockTypes[block.type];

      return <Component key={block.id} block={block} />;
    });
};

const blockTypes: Record<string, React.ComponentType<NotionProps>> = {
  color_block: dynamic(() => import('notion/NotionColorBlockStandalone')),
  color_palette: dynamic(() => import('notion/NotionColorChart')),
  color_contrast: dynamic(() => import('notion/NotionColorContrast')),
  color_donut: dynamic(() => import('notion/NotionColorDonut')),
  color_scheme: dynamic(() => import('notion/NotionColorScheme')),
  color_usage: dynamic(() => import('notion/NotionColorUsage')),
  grid: dynamic(() => import('notion/NotionGrid')),
  grid_item: dynamic(() => import('notion/NotionGridItem')),
  h2: dynamic(() => import('notion/NotionH2')),
  h3: dynamic(() => import('notion/NotionH3')),
  h4: dynamic(() => import('notion/NotionH4')),
  oip_image: dynamic(() => import('notion/NotionImage')),
  image_link: dynamic(() => import('notion/NotionImageLink')),
  list: dynamic(() => import('notion/NotionList')),
  paragraph: dynamic(() => import('notion/NotionParagraph')),
  spacer: dynamic(() => import('notion/NotionSpacer')),
  text_statement: dynamic(() => import('notion/NotionTextStatement')),
  type_scale: dynamic(() => import('notion/NotionTypeScale')),
  oip_video: dynamic(() => import('notion/NotionVideo')),
  video_link: dynamic(() => import('notion/NotionVideoLink')),
  content_store: dynamic(() => import('notion/NotionContentStore')),
  vertical_palette: dynamic(() => import('organisms/VerticalPalette')),
};

export default createComponents;
