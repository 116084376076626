import { posix as path } from 'path';

import toSlug from '@collab/utils/toSlug';

import { Asset, PageHeadings, PageMeta } from 'types/contentService';

const imageRoot = '/images';
const assetContentPath = path.join(imageRoot, 'pages');
const assetSeoPath = path.join(imageRoot, 'seo');

export const getAssetSrc = (
  pagePath: string,
  imageBlock: any,
  assets: Asset[],
) => {
  const asset = getAsset(imageBlock.id, assets);
  return path.join(assetContentPath, pagePath, asset.fileName);
};

export const getSeoAssetSrc = (seoId: string, assets: Asset[]): string => {
  const asset = getAsset(seoId, assets);
  return path.join(assetSeoPath, asset.fileName);
};

const getAsset = (assetId: string, assets: Asset[]) => {
  const asset = assets.find((asset) => asset.id === assetId);

  if (!asset) {
    throw new MissingAsset(assetId);
  }

  return asset;
};

export const getHref = (
  href: string,
  pages: PageMeta[],
  headings: PageHeadings[],
) => {
  if (href.startsWith('http')) return href;

  const [pageId, anchorId] = href.replace('/', '').split('#');

  const page = getPage(pageId, pages);
  const anchor = getAnchor(pageId, anchorId, headings);

  return page ? `/${page.path}${anchor}` : href;
};

const getAnchor = (
  pageId: string,
  anchorId: string | undefined,
  headings: PageHeadings[],
) => {
  if (!anchorId) return '';

  const page = getPage(pageId, headings);
  const heading = Object.entries(page.headings).find(
    ([id]) => id.replace(/-/g, '') === anchorId,
  );

  if (!heading) {
    throw new InvalidAnchorId(anchorId);
  }

  return heading ? `#${toSlug(heading[1])}` : '';
};

const getPage = <T extends PageMeta | PageHeadings>(
  pageId: string,
  pages: T[],
): T => {
  const page = pages.find((page) => page.pageId.replace(/-/g, '') === pageId);
  if (!page) {
    throw new InvalidPageId(pageId);
  }

  return page;
};

class InvalidAnchorId extends Error {
  constructor(AnchorId: string) {
    super(`Invalid anchor id: ${AnchorId}`);
  }
}

class InvalidPageId extends Error {
  constructor(pageId: string) {
    super(`Invalid page id: ${pageId}`);
  }
}

class MissingAsset extends Error {
  constructor(id: string) {
    super(`Could not find asset with id: ${id}`);
  }
}

export class InvalidLink extends Error {
  constructor(page: string, linkText: string, cause: string) {
    super(`Invalid link at '/${page}' ('${linkText}'). ${cause}`);
  }
}
