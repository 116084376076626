import { createContext, useContext } from 'react';

import { PageHeadings, PageMeta } from 'types/contentService';

export type PageContextState = {
  pageId: string;
  pagePath: string;
  assets: any[];
  category: string[];
  pageMetas: PageMeta[];
  headings: PageHeadings[];
};

const PageContext = createContext({} as PageContextState);

export const usePageContext = () => useContext(PageContext);

const PageProvider: React.FC<{
  state: PageContextState;
  children: React.ReactNode;
}> = ({ state, children }) => (
  <PageContext.Provider value={state}>{children}</PageContext.Provider>
);

export default PageProvider;
