import { GetStaticPaths, GetStaticProps } from 'next';
import { PHASE_PRODUCTION_BUILD } from 'next/dist/shared/lib/constants';

import logger from '@collab/libs/server/logger';

import {
  fetchPageMetas,
  isLandingPagePath,
} from 'libs/page-generator/fileLoaders';
import getPageProps from 'libs/page-generator/getPageProps';
import DesignPage from 'pages/DesignPage';

export const getStaticPaths: GetStaticPaths = async () => {
  const pageMetas = await fetchPageMetas();

  const paths = pageMetas.map((page) => ({
    params: {
      slug: isLandingPagePath(page.path) ? undefined : page.path.split('/'),
    },
  }));

  return {
    paths,
    fallback: false,
  };
};

export const getStaticProps: GetStaticProps<any> = async ({ params }) => {
  if (!params) throw new MissingParams();

  const slug = Array.isArray(params.slug) ? params.slug.join('/') : '';

  const pageMetas = await fetchPageMetas();
  const pageMeta = pageMetas.find((meta) => meta.path === slug);

  if (!pageMeta) {
    if (process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD) {
      throw new MissingPageMeta(slug);
    }

    logger.warn(`Unknown params at runtime ${JSON.stringify(params)}`);
    return { notFound: true };
  }

  return getPageProps(pageMeta, pageMetas, slug);
};

class MissingPageMeta extends Error {
  constructor(slug: string) {
    super(`Could not find PageMeta for slug '${slug}'`);
  }
}

class MissingParams extends Error {
  constructor() {
    super('Missing params');
  }
}

export default DesignPage;
