import dynamic from 'next/dynamic';

import { ContentWrapper } from '@collab/atoms';
import { PageTitle } from '@collab/molecules';

import PageProvider from 'libs/notion-renderer/PageProvider';
import createComponents from 'libs/notion-renderer/createComponents';
import { getAssetSrc } from 'libs/notion-renderer/utils';
import { SeoContent } from 'libs/page-generator/getPageSeo';
import PageSeo from 'libs/seo/PageSeo';
import { PageHeadings, PageMeta } from 'types/contentService';

const HeroVideo = dynamic(() => import('molecules/HeroVideo'));
const HeroImage = dynamic(
  () => import('@collab/molecules/HeroImage/HeroImage'),
);
const BrandIntro = dynamic(() => import('organisms/BrandIntro'));

const DesignPage: React.FC<{
  pageData: any;
  pageMetas: PageMeta[];
  seo: SeoContent;
  headings: PageHeadings[];
}> = ({ pageData, pageMetas, seo, headings }) => {
  const { pageId, path, category: pageCategory, title } = pageData.pageMeta;
  const pageComponents = createComponents(pageData.content);
  const heroImage = getHeroImage(pageData, pageData.assets, path);
  const heroVideo = getHeroVideo(pageData, pageData.assets, path);
  const category = getCategory(pageCategory);

  const showBrandIntro = path === '';

  return (
    <>
      <PageSeo {...seo} />
      <PageProvider
        state={{
          pageId,
          pagePath: path,
          assets: pageData.assets,
          category,
          pageMetas,
          headings,
        }}
      >
        <ContentWrapper>
          <PageTitle title={title} category={category} />
        </ContentWrapper>
        {heroImage && <HeroImage {...heroImage} />}
        {heroVideo && <HeroVideo {...heroVideo} />}
        <ContentWrapper>
          {pageComponents}
          {showBrandIntro && <BrandIntro />}
        </ContentWrapper>
      </PageProvider>
    </>
  );
};

const getHeroImage = (pageData: any, assets: any[], pagePath: string) => {
  if (!pageData.heroImageBlock) {
    return null;
  }
  const { mobileImage, alt } = pageData.heroImageBlock;

  const src = getAssetSrc(pagePath, pageData.heroImageBlock, assets);
  const srcMobile = mobileImage && getAssetSrc(pagePath, mobileImage, assets);

  return { alt, src, srcMobile };
};

const getHeroVideo = (pageData: any, assets: any[], pagePath: string) => {
  if (!pageData.heroVideoBlock) {
    return null;
  }
  const { poster, srcWebM, srcMp4 } = normalizeHeroVideoSources(
    pageData.heroVideoBlock,
  );

  return {
    poster: getAssetSrc(pagePath, poster, assets),
    srcWebM: getAssetSrc(pagePath, srcWebM, assets),
    srcMp4: getAssetSrc(pagePath, srcMp4, assets),
  };
};

const getCategory = (category: any) => {
  if (Array.isArray(category)) {
    return category;
  }

  return category ? [category] : [];
};

const normalizeHeroVideoSources = ({ poster, srcWebM, srcMp4 }: any) => ({
  poster,
  srcWebM,
  srcMp4,
});

export default DesignPage;
